import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '@components/Button/index';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function Platform404() {
  return (
    <>
      <GatsbySeo
        title="404 | Page Not Found"
        description="The requested page could not be found."
        noindex={true}
      ></GatsbySeo>
      <div className="container flex h-screen w-screen flex-col items-center justify-center gap-2 ">
        <StaticImage
          loading="lazy"
          src="../../../images/platform_404.png"
          placeholder="blurred"
          alt="404"
          className=" -mt-20 w-full sm:max-w-[400px]"
        />
        <div className="mb-5 text-center">
          <h1>404</h1>
          <h4>The page could not be found.</h4>
        </div>
        <a href="/learn/home">
          <Button type="primary" className="bg-[#009BFF]">
            Return to Home
          </Button>
        </a>
      </div>
    </>
  );
}
